


















































































































































.live-content {
    height: 100%;
    width: 100%;
    background-color: #070932;
    display: flex;
    flex-direction: column;

    .live-title {
        padding: 20px;
        font-size: 18px;
        color: #fff;
    }
}

.main-content {
    height: 100%;
    width: 100%;
    margin: 10px auto 60px;
    display: flex;
    justify-content: center;
}

.left-content {
    height: 100%;
    width: 26%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background-image: url('../../assets/image/student/live_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.right-content {
    width: 40%;
    height: 100%;
    margin-left: 20px;
}

.right-top {
    height: 75%;
    background: #0C0E3F;
    border-radius: 10px;
    overflow: hidden;

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .show-content {
        margin: 20px;
        color: #fff;
        opacity: 0.3;
        line-height: 24px;
        height: calc(100% - 40px);
    }
}

.right-bottom {
    margin-top: 20px;
    height: calc(25% - 20px);
    background: #0C0E3F;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.live-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
}
